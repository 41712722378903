import React from 'react';

import * as styles from './ProductGroupEntry.module.css';
import Slugger from '@mangoart/gatsby-ui/helper/Slugger';
import Button from '@mangoart/gatsby-ui/components/ezagrar/Newsletter/Button';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';

export const ProductGroupEntry = ({ data }) => {
  const { name, boximage: image, manufacturer } = data;

  const manufacturerName = manufacturer.name;
  const extraPath = `/produkte/${Slugger.slug(manufacturerName)}/${Slugger.slug(name)}`;

  const navigateToDetailPage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(extraPath);
  };

  return (
    <section className={styles.ProductGroupEntry} onClick={navigateToDetailPage} role="presentation">
      <div className={styles.top}>
        <GatsbyImage alt={name} imgStyle={{ height: 200 }} style={{ height: 200 }} image={getImage(image)} />
      </div>
      <div className={styles.logo}>{name}</div>
      <div className={styles.action}>
        <Button onClick={navigateToDetailPage} to={extraPath} icon="arrow-right">
          Weiterlesen
        </Button>
      </div>
    </section>
  );
};
