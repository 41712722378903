import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';

import * as styles from './Button.module.css';

export default function Button({ to, extraPath, color, icon, children, style, onClick }) {
  const isAbsolute = to && to.indexOf('://') !== -1;
  const colorClass = color ? color : '';

  return isAbsolute ? (
    <a className={styles['button ' + colorClass]} href={to} target="_blank" style={style} onClick={onClick}>
      {children}
      {icon && <span className={clsx('icon-' + icon, styles.icon)} />}
    </a>
  ) : (
    <Link className={clsx(styles.button, styles[colorClass])} to={to} style={style} onClick={onClick}>
      {children}
      {icon && <span className={clsx('icon-' + icon, styles.icon)} />}
    </Link>
  );
}
