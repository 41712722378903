import React from 'react';

import * as styles from './Hersteller.module.css';
import { ProductGroupEntry } from './ProductGroupEntry';
import { HerstellerEntry } from './HerstellerEntry';
import classNames from 'classnames';
import { PageTitle } from '@mangoart/gatsby-ui/components/ezagrar/PageTitle';

const sortHerstellerFunction = (a, b) => {
  if (a.starred && b.starred) {
    return a.order - b.order;
  } else if (a.starred) {
    return -1;
  } else if (b.starred) {
    return 1;
  }
  return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
};

const renderHersteller = (data) => <HerstellerEntry data={data} key={data.id} />;

const renderProductGroup = (data) => (data.published ? <ProductGroupEntry data={data} key={data.id} /> : null);

export const Hersteller = ({ data }) => {
  const sortedByCategory = {};
  data.hersteller.edges.forEach(({ node }) => {
    sortedByCategory[node.category] = sortedByCategory[node.category] || [];
    sortedByCategory[node.category].push(node);
  });

  const productGroupsSortedByManufacturer = {};
  data.productGroups.edges.forEach(({ node }) => {
    const manufacturerName = node.manufacturer.name;
    productGroupsSortedByManufacturer[manufacturerName] = productGroupsSortedByManufacturer[manufacturerName] || [];
    productGroupsSortedByManufacturer[manufacturerName].push(node);
  });

  return (
    <section className="defaultWidth" style={{ marginBottom: 8 }}>
      <PageTitle data={data}>Same</PageTitle>
      <div className={classNames(styles.content)}>
        <span id="same" className={styles.anchor} />
        {productGroupsSortedByManufacturer.Same.map(renderProductGroup)}
      </div>

      <PageTitle data={data}>Lamborghini</PageTitle>
      <div className={classNames(styles.content)}>
        <span id="lamborghini" className={styles.anchor} />
        {productGroupsSortedByManufacturer.Lamborghini.map(renderProductGroup)}
      </div>

      {/* }
      <PageTitle data={data}>LS Traktoren</PageTitle>
      <div className={classNames(styles.content)}>
        <span id="lstractor" className={styles.anchor} />
        {productGroupsSortedByManufacturer['LS Traktoren'].map(renderProductGroup)}
      </div> */}

      <PageTitle data={data}>Zubehör</PageTitle>
      <div className={classNames(styles.content)}>
        <span id="zubehoer" className={styles.anchor} />
        {sortedByCategory.Ersatzteil.sort(sortHerstellerFunction).map(renderHersteller)}
      </div>
    </section>
  );
};
