import React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';
import { PageTitle } from '@mangoart/gatsby-ui/components/ezagrar/PageTitle';
import { Hersteller } from '../../components/Hersteller';
import DefaultLayout from '../../layout/DefaultLayout';

const HerstellerPage = ({ data, location }) => {
  const { pageData, siteData, hersteller, productGroups } = data;
  const { siteMetadata } = siteData;
  const { metadata, opengraph } = pageData;
  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <PageTitle>Hersteller</PageTitle>
      <Hersteller data={{ hersteller, productGroups }} />
    </DefaultLayout>
  );
};

export default HerstellerPage;

export const HerstellerPageQuery = graphql`
  query HerstellerPageQuery {
    siteData: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    pageData: cockpitPageHersteller {
      content {
        title
        icon
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          id
          childImageSharp {
            gatsbyImageData(width: 1300, height: 630)
          }
        }
      }
    }
    productGroups: allCockpitProduktgruppe {
      edges {
        node {
          id
          published
          name
          boximage {
            childImageSharp {
              gatsbyImageData(width: 640, aspectRatio: 2.2)
            }
          }
          manufacturer {
            name
          }
        }
      }
    }
    hersteller: allCockpitHersteller {
      edges {
        node {
          id
          name
          published
          starred
          slug
          description
          site
          category
          products {
            name
            image {
              childImageSharp {
                gatsbyImageData(width: 640)
              }
            }
          }
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 640)
            }
          }
          logopng {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          logosvg {
            childImageSharp {
              gatsbyImageData
            }
          }
          logojpg {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
